import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import classNames from "classnames";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import ArrowLeft from "../../ui/icon/arrow-left.svg?react";
import Close from "../../ui/icon/close.svg?react";
import styles from "./BoardModal.module.css";
import { Board } from "./utils";

interface BoardModalProps {
  board: Board;
  open: boolean;
  onClose: () => void;
}

const BoardModal = ({ board, open, onClose }: BoardModalProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <Dialog open={open} onClose={onClose} className={styles.boardModal}>
      <DialogBackdrop className={styles.backdrop} />
      <DialogPanel className={classNames(styles.dialog)}>
        <div className={styles.innerDialog}>
          <DialogTitle as="div" className={styles.header}>
            <Headline
              size={2}
              title={i18n.boards[board.type]}
              as="div"
              className={styles.headerTitle}
            />
            <Button
              layout="link"
              buttonProps={{
                onClick: onClose,
                title: i18n.boards.back,
                "aria-label": i18n.boards.back,
              }}
            >
              <span className={styles.closeText}>
                <ArrowLeft className={styles.closeArrow} />
                {i18n.boards.back}
              </span>
              <span className={styles.closeIcon}>
                <Close className={styles.icon} />
              </span>
            </Button>
          </DialogTitle>
          <div className={styles.dialogContent}>
            <Headline
              size={2}
              title={i18n.boards[board.type]}
              as="h1"
              className={styles.headline}
            />
            <CenterWrapper>
              <Headline
                size={4}
                title={board.description}
                as="h2"
                className={styles.description}
              />
            </CenterWrapper>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default BoardModal;
