import { I18n, RoomTerm } from "../@types";
import { BoardType } from "../http/ratePlanApi";
import { getRoomTerm } from "./utils";

const it: I18n = {
  start: {
    hgv: "Hoteliers- und Gastwirteverband",
    email: "E-Mail senden",
    phone: "Anrufen",
    privacy: "Privacy",
    termsAndConditions: "Termini & condizioni generali",
    adultsCountSummary: (count: number) => {
      return `${count} ${count === 1 ? "adulto" : "adulti"}`;
    },
    guestsCountSummary: (count: number) => {
      return `${count} ${count === 1 ? "ospite" : "ospiti"}`;
    },
    roomsCountSummary: (count: number, term: RoomTerm) => {
      return `${count} ${
        count === 1
          ? getRoomTerm({
              term,
              room: "camera",
              apartment: "appartamento",
              suite: "suite",
            })
          : getRoomTerm({
              term,
              room: "camere",
              apartment: "appartamenti",
              suite: "suite",
            })
      }`;
    },
    guestsAndRooms: (term: RoomTerm) =>
      `Ospiti & ${getRoomTerm({
        term,
        room: "camere",
        apartment: "appartamenti",
        suite: "suite",
      })}`,
    travelPeriod: "Periodo di viaggio",
    nights: (nights: number) => {
      return `(${nights} ${nights === 1 ? "Nacht" : "Nächte"})`;
    },
    arrival: "Check-in?",
    departure: "Check-out?",
    yourBooking: {
      formal: "La vostra prenotazione",
      informal: "La tua prenotazione",
    },
    guests: "Gäste",
  },
  occupancy: {
    guestsCountSummary: (count: number) => {
      return `${count} ${count === 1 ? "Gast" : "Gäste"}`;
    },
    apply: "Anwenden",
    guestsAndRooms: (term: RoomTerm) =>
      `Ospiti & ${getRoomTerm({
        term,
        room: "camere",
        apartment: "appartamenti",
        suite: "suite",
      })}`,
    room: (term: RoomTerm) =>
      getRoomTerm({
        term,
        room: "Camera",
        apartment: "Appartamento",
        suite: "Suite",
      }),
    autoOccupancy: (term: RoomTerm) =>
      `Gäste automatisch auf ${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartments",
        suite: "Suiten",
      })} aufteilen.`,
    autoOccupancyInfo: (term: RoomTerm) =>
      `Verschiedene Belegungs- und ${getRoomTerm({
        term,
        room: "Zimmervorschläge",
        apartment: "Apartment-Vorschläge",
        suite: "Suite-Vorschläge",
      })} für die ausgewählte Gästeanzahl erhalten.`,
    adults: "Erwachsene",
    adultsInfo: "Ab 18 Jahren",
    children: "Kinder",
    childrenInfo: (minAge: number) => {
      return `Alter: ${minAge}–17 Jahre`;
    },
    childrenAge: (count: number) => {
      return `Alter Kind ${count}`;
    },
    selectAge: "Alter wählen",
  },
  calendar: {
    arrival: "Anreise?",
    departure: "Abreise?",
    continueWithNights: (nights: number) => {
      return `Weiter mit ${nights} ${nights === 1 ? "Nacht" : "Nächten"}`;
    },
    arrivalNotAvailable: "Anreise an diesem Tag nicht möglich",
    departureNotAvailable: "Abreise an diesem Tag nicht möglich",
    departureBeforeArrival: "Abreise vor Anreise nicht möglich",
    minStay: (nights: number) => {
      return `Mindestaufenthalt ${nights} ${nights === 1 ? "Nacht" : "Nächte"}`;
    },
    maxStay: (nights: number) => {
      return `Maximalaufenthalt ${nights} ${nights === 1 ? "Nacht" : "Nächte"}`;
    },
  },
  general: {
    close: "Schließen",
    delete: "Löschen",
    expand: "Öffnen",
    less: "Weniger",
    more: "Mehr",
    share: "Teilen",
    edit: "Bearbeiten",
    openLightbox: "Bildergalerie öffnen",
  },
  autoOccupancy: {
    apply: "Anwenden",
    adults: "Erwachsene",
    adultsInfo: "Ab 18 Jahren",
    children: "Kinder",
    childrenInfo: (minAge: number) => {
      return `Alter: ${minAge}–17 Jahre`;
    },
    guestsCountSummary: (count: number) => {
      return `${count} ${count === 1 ? "Gast" : "Gäste"}`;
    },
    ourSuggestion: {
      formal: "Unser Vorschlag für Sie",
      informal: "Unser Vorschlag für dich",
    },
    noSuitableSuggestion: "Kein passender Vorschlag dabei?",
    splitGuests: {
      formal: (term: RoomTerm) => {
        return `Teilen Sie die ausgewählten Gäste auf ein oder mehrere ${getRoomTerm(
          {
            term,
            room: "Zimmer",
            apartment: "Apartments",
            suite: "Suiten",
          },
        )} auf.`;
      },
      informal: (term: RoomTerm) => {
        return `Teile die ausgewählten Gäste auf ein oder mehrere ${getRoomTerm(
          {
            term,
            room: "Zimmer",
            apartment: "Apartments",
            suite: "Suiten",
          },
        )} auf.`;
      },
    },
    assignGuestsIndividually: "Gäste individuell zuweisen",
    option: (index: number) => {
      return `Option ${index}`;
    },
    bookOption: (index: number) => {
      return `Option ${index} buchen`;
    },
    from: "ab",
    totalPriceNights: (nights: number) => {
      return `Gesamtpreis für ${nights} ${nights === 1 ? "Nacht" : "Nächte"}`;
    },
    occupancy: (
      adults: number,
      children: number,
      rooms: number,
      term: RoomTerm,
    ) => {
      return `${adults + children} ${children > 0 ? "Gäste" : adults > 1 ? "Erwachsene" : "Erwachsener"} aufgeteilt auf ${rooms} ${
        rooms === 1
          ? getRoomTerm({
              term,
              room: "Zimmer",
              apartment: "Apartment",
              suite: "Suite",
            })
          : getRoomTerm({
              term,
              room: "Zimmer",
              apartment: "Apartments",
              suite: "Suiten",
            })
      }`;
    },
    roomOccupancy: (adults: number, children: number) => {
      return `Belegung: ${adults + children} ${children > 0 ? "Gäste" : adults > 1 ? "Erwachsene" : "Erwachsener"}`;
    },
    customOffer: {
      formal: (term: RoomTerm) => {
        return `Teilen Sie die ausgewählten Gäste auf ein oder mehrere ${getRoomTerm(
          {
            term,
            room: "Zimmer",
            apartment: "Apartments",
            suite: "Suiten",
          },
        )} auf.`;
      },
      informal: (term: RoomTerm) => {
        return `Teile die ausgewählten Gäste auf ein oder mehrere ${getRoomTerm(
          {
            term,
            room: "Zimmer",
            apartment: "Apartments",
            suite: "Suiten",
          },
        )} auf.`;
      },
    },
    adultsCount: (count: number) => {
      return `${count} ${count === 1 ? "Erwachsener" : "Erwachsene"}`;
    },
    childrenCount: (count: number) => {
      return `${count} ${count === 1 ? "Kind" : "Kinder"}`;
    },
    roomIndex: (term: RoomTerm, index: number) => {
      return `${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`;
    },
    applySort: "Anwenden",
    showAllRooms: (term: RoomTerm) => {
      return `Alle ${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartments",
        suite: "Suiten",
      })} anzeigen`;
    },
    childrenAge: (age: number) => {
      return `Kind ${age} ${age === 1 ? "Jahr" : "Jahre"}`;
    },
  },
  roomConfigurations: {
    selectRoom: {
      formal: (term: RoomTerm) => {
        return `Wählen Sie ${getRoomTerm({
          term,
          room: "das Zimmer",
          apartment: "das Apartment",
          suite: "die Suite",
        })}`;
      },
      informal: (term: RoomTerm) => {
        return `Wähle ${getRoomTerm({
          term,
          room: "das Zimmer",
          apartment: "das Apartment",
          suite: "die Suite",
        })}`;
      },
    },
    continue: "Weiter",
    room: (term: RoomTerm) => {
      return getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      });
    },
    roomIndex: (term: RoomTerm, index: number) => {
      return `${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`;
    },
    adults: (count: number) => {
      return `${count} ${count === 1 ? "Erwachsener" : "Erwachsene"}`;
    },
    children: (count: number) => {
      return `${count} ${count === 1 ? "Kind" : "Kinder"}`;
    },
    guests: "Gäste",
    ratePlan: "Rate",
    mealType: "Verpflegung",
  },
  rooms: {
    occupancy: "Belegung",
    selectRoom: {
      formal: (term: RoomTerm) => {
        return `Wählen Sie ${getRoomTerm({
          term,
          room: "das Zimmer",
          apartment: "das Apartment",
          suite: "die Suite",
        })}`;
      },
      informal: (term: RoomTerm) => {
        return `Wähle ${getRoomTerm({
          term,
          room: "das Zimmer",
          apartment: "das Apartment",
          suite: "die Suite",
        })}`;
      },
    },
    continue: "Weiter",
    availableRooms: (availableRooms: number) => {
      return `Nur noch ${availableRooms} verfügbar`;
    },
    save: (discount: number) => {
      return `${discount}\xa0% sparen`;
    },
    nights: (nights: number) => {
      return `${nights} ${nights === 1 ? "Nacht" : "Nächte"}`;
    },
    nightPerRoom: (term: RoomTerm) => {
      return `Nacht pro ${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })}`;
    },
    showPortalPrices: "Preisvergleich mit Buchungsportalen einblenden",
    amenities: (term: RoomTerm) => {
      return `Das bietet ${getRoomTerm({
        term,
        room: "das Zimmer",
        apartment: "das Apartment",
        suite: "die Suite",
      })}`;
    },
    showMore: "Mehr anzeigen",
    backToOverview: "Zurück zur Übersicht",
    sort: "Sortieren",
    sortHeadline: {
      formal: (term: RoomTerm) => {
        return `Sortieren Sie Ihre ${getRoomTerm({
          term,
          room: "Zimmerliste",
          apartment: "Apartmentliste",
          suite: "Suitenliste",
        })} nach Belieben`;
      },
      informal: (term: RoomTerm) => {
        return `Sortiere deine ${getRoomTerm({
          term,
          room: "Zimmerliste",
          apartment: "Apartmentliste",
          suite: "Suitenliste",
        })} nach Belieben`;
      },
    },
    room: (term: RoomTerm, index: number) => {
      return `${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`;
    },
    price: "Preis",
    size: "Größe (m²)",
    ascending: "Aufsteigend",
    ascendingText: "Von klein nach groß",
    descending: "Absteigend",
    descendingText: "Von groß nach klein",
    resetSort: "Alle zurücksetzen",
    applySort: "Anwenden",
    back: "Zurück",
    showAllImages: "Alle Bilder anzeigen",
    roomOption: (option: number, term: RoomTerm) => {
      return `${getRoomTerm({
        term,
        room: "Dieses Zimmer",
        apartment: "Dieses Apartment",
        suite: "Diese Suite",
      })} ist Teil von Option ${option}`;
    },
  },
  ratePlans: {
    continue: "Weiter",
    selectRate: "Rate wählen",
    showMoreDetails: "Mehr Details anzeigen",
    back: "Zurück",
    showAllImages: "Alle Bilder anzeigen",
    general: "Allgemein",
    inclusives: "Inklusivleistungen",
    boardsTitle: "Verpflegungsoptionen",
    boardsInfo: "(kann im nächsten Schritt gewählt werden)",
    reservationConditions: "Reservierungsbedingungen",
    includedInRate: "(in der Rate inklusive)",
    selectAndContinue: "Wählen & weiter",
    showMoreOptions: "Weitere Optionen anzeigen",
    readMore: "Mehr lesen",
  },
  boards: {
    continue: "Wählen & abschließen",
    [BoardType.OvernightStayOnly]: "Nur Übernachtung",
    [BoardType.BedAndBreakfast]: "Übernachtung mit Frühstück",
    [BoardType.HalfBoard]: "Halbpension",
    [BoardType.ThreeQuarterBoard]: "3/4 Pension",
    [BoardType.FullBoard]: "Vollpension",
    [BoardType.AllInclusive]: "All Inclusive",
    selectBoard: "Verpflegung wählen",
    included: "Inklusive",
    moreInfos: "Mehr Infos",
    nightsAndGuests: (nights: number, adults: number, children: number) => {
      return `Für ${nights} ${nights === 1 ? "Übernachtung" : "Übernachtungen"} & ${children > 0 ? `${adults + children} Gäste` : `${adults} Erwachsene`}`;
    },
    back: "Zurück",
    backToRoom: (rooom: string) => {
      return `Zurück zu: ${rooom}`;
    },
  },
  extras: {
    selectExtras: "Extras wählen",
    goToCheckout: "Zur Zusammenfassung der Buchung",
  },
};

export default it;
